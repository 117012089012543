<template>
  <div class="goods-infor">
    <div class="goods-card container">
      <div class="goods-card-title">
        <div>
          <div v-if="goodsTags.head" class="lable" style="margin-right: .2rem;"
            :style="{background:goodsTags.head.bg_color,color:goodsTags.head.font_color}">
            {{goodsTags.head.text}}</div>
          <span>{{ goodsData.title }}</span>
          <div v-if="goodsTags.end" class="lable" style="margin-left: .2rem;"
            :style="{background:goodsTags.end.bg_color,color:goodsTags.end.font_color}">
            {{goodsTags.end.text}}
          </div>

        </div>
        <p class="subtitle" v-if="goodsData.subtitle">{{ goodsData.subtitle }}</p>
      </div>

      <div class="goods-card-content">
        <div class="goods-specs">
          <div class="goods-specs-item">
            <div class="name">价格</div>
            <div class="content price">
              <!--普通商品-->
              <p class="price-text price-text_new">
                <span class="symbol">￥</span>
                <!--允许单买-->
                <!--                <span class="number" v-if="goodsData.group.is_single===1">{{ goodsData.group.single_price }}</span>-->
                <!--不允许单买-->
                <!--                <span class="number" v-else>{{ goodsData.group.price }}</span>-->
                <!--                只显示单价-->
                <span class="number">{{ goodsData.group.single_price }}</span>
              </p>
            </div>
          </div>
          <!--商品规格-->
          <div class="goods-specs-item" v-if="goodsData.case.status === 1&&goodsData.case.text">
            <div class="name">规格</div>
            <div class="content">
              <span class="text">
                {{ goodsData.case.text }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 老师信息 -->
    <div v-if="goodsData.teacher.ids.length>0" class="goods-card container goods-card_teacher">
      <div class="goods-card-title">授课老师</div>
      <div class="goods-card-content">
        <teacher-list :list="teacherList" />
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import teacherList from '@/components/goods/teacher-list'

export default {
  name: 'goods-infor',
  props: ['goodsData', 'teacherList', 'is_buy'],
  components: {
    teacherList,
  },
  data () {
    return {}
  },
  computed: {
    newPrice () {
      let price = this.currentSpecs?.amount || this.goodsData.product.price
      return this.$util.clearPriceZero(price)
    },
    goodsTags () {
      return this.goodsData.title_tags || {}
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.goods {
  &-infor {
    background-color: #fff;
    //border-bottom: 0.21rem solid #f7f8fa;
  }

  &-card {
    padding-top: 0.2rem;
    padding-bottom: 0.5rem;

    &+& {
      border-top: 0.01rem solid #f7f8fa;
    }

    &-title {
      font-size: 0.48rem;
      line-height: 0.8rem;
      color: $color_black;
      font-weight: bold;
      margin-bottom: 0.2rem;

      .lable {
        display: inline-block;
        height: 0.5rem;
        line-height: .5rem;
        opacity: 1;
        font-size: 0.32rem;
        background: #3C3D42;
        color: #FFFFFF;
        padding: 0 .1rem;
        border-radius: 0.05rem;
        transform: translateY(-.05rem);

      }

      .subtitle {
        font-size: 0.4rem;
        line-height: 1.3;
        color: #919499;
        font-weight: normal;
        margin-top: .2rem;
      }
    }

    &_teacher {
      padding-top: .53rem;
      border: none;
    }
  }

  &-specs {
    &-list {
      .van-cell::after {
        background: red;
        display: none;
      }
    }

    &-item {
      display: flex;
      padding: 0.2rem 0;

      .name {
        width: 1.28rem;
        font-size: 0.4rem;
        color: #919499;
        line-height: 0.4rem;
      }

      .content {
        position: relative;
        display: flex;
        align-items: flex-end;
        flex: 1;
        font-size: 0;

        span {
          font-size: 0.4rem;
          line-height: 0.4rem;
        }

        .icon {
          position: absolute;
          width: 0.5rem;
          top: 0;
          right: 0;
        }
      }

      .price {
        &-text {
          .symbol {
            font-size: 0.35rem;
          }

          .number {
            font-size: 0.42rem;
            font-family: DIN-Medium;
            font-weight: bold;
          }

          &_new {
            color: #ff6a4d;
          }

          &_old {
            color: #919499;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}
</style>
