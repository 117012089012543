<template>
  <div v-if="pageLoadingComplete" class="container-wapper ">
    <template v-if="goodsData">
      <commonHeader :show-order="true" />
      <!--手动成团且拼团中，或者自动成团-->
      <!-- <template>
        <groupGoodsPeoples style="border-top: 1px solid #F7F8FA" :record="goodsRecord" @finish="peoplesFinish"
          @groupButton="joinGroupBuyButton" />
        <div class="line" />
      </template> -->

      <!--有轮播图片-->
      <template v-if="goodsData.img.list.length">
        <goodsSwipe :bookList="goodsData.img.list" />
        <groupCountdown :data="goodsGroup" @finish="goodsFinish" />
        <goodsInfor :goodsData="goodsData" :teacherList="teacherList">
          <groupGoodsTips />
        </goodsInfor>
        <div class="line" />
      </template>
      <!--没有轮播图片-->
      <template v-else>
        <goodsInfor :goodsData="goodsData" :teacherList="teacherList">
          <groupCountdown :data="goodsGroup" @finish="goodsFinish" @groupButton="joinGroupBuyButton" />
          <groupGoodsTips />
        </goodsInfor>
      </template>

      <!--手动成团时且团不是进行中且团未成功或未加入，显示团推荐列表-->
      <template v-if="isManualGroup">

        <div class="container" v-if="showRecommendGroupList.length">
          <goods-group-peoples-title :isSuccsess="buttonRecord.status === 2" />
        </div>
        <groupGoodsPeoples v-for="(item, index) in showRecommendGroupList" :key="index" :show-title="false" :record="item"
          @finish="peoplesFinish" @groupButton="joinGroupBuyButton" />
        <div v-if="isPeoplesButton" class="peoples-button" @click="isPeoplesButtonActive = true">查看全部</div>
        <div class="line" />
      </template>

      <!-- 商品介绍-->
      <div v-if="goodsData.introduce_img" class="goods-content">
        <img :src="goodsData.introduce_img" alt="" class="image" />
      </div>

      <!--底部悬浮-->
      <footerFixed :dialogue-show="true" :dialogue-url="goodsData.customer.url"
        v-if="!goodsGroupTimeOver || (goodsGroupTimeOver && isSingle) || buttonRecord.status === 2">

        <!--参团中-->
        <template v-if="buttonRecord.is_join === 1">
          <!--团进行中-->
          <template v-if="buttonRecord.status === 1">
            <buttonShare :text="inviteButtnText" />
          </template>

          <!--团成功-->
          <template v-else-if="buttonRecord.status === 2">
            <!-- 绑定app课程-->
            <buttonApp v-if="goodsData.goods.brand" :brand="goodsData.goods.brand" :no="goodsData.goods.item_no"
              :province="goodsData.goods.province" :type="goodsData.goods.type" @onClick="clickOpenApp" />
            <!--没有绑定课程-->
            <pageBottomButton v-else color="red" buttonType="text" text="查看详情" @click="selectBuyButton" />
          </template>

          <!--团失败-->
          <template v-else>
            <!--可单买-->
            <pageBottomButton v-if="isSingle" color="yellow" buttonType="price" text="单独购买"
              :text-price="goodsGroup.single_price" @click="singeBuyButton" />

            <!--团购买按钮-->
            <pageBottomButton color="red" buttonType="price" :text="groupBuyButtonText" :text-price="goodsGroup.price"
              @click="groupBuyButton" />
          </template>
        </template>

        <!--未参团-->
        <template v-else>
          <!--商品团购结束-->
          <template v-if="goodsGroupTimeOver">
            <!--            商品团购结束，且无法单买时，按钮禁用-->
            <!--            <pageBottomButton-->
            <!--                color="red"-->
            <!--                buttonType="text"-->
            <!--                text="立即购买"-->
            <!--                @click="singeBuyButton"-->
            <!--                :disabled="!isSingle"-->
            <!--            />-->
          </template>

          <!--商品团购进行中-->
          <template v-else>
            <!--指定成团，链接中的拼团正在成团中-->
            <template v-if="goodsRecord.status === 1">
              <!--自己开一个新团-->
              <!--              <pageBottomButton-->
              <!--                  color="yellow"-->
              <!--                  text="发起拼团"-->
              <!--                  @click="groupBuyButton"-->
              <!--              />-->

              <!--参与拼团-->
              <pageBottomButton color="red" :text="'￥' + goodsGroup.price + '参与拼团'" @click="joinGroupBuyButton" />
            </template>
            <!--自动成团 且没有拼团id-->
            <template v-else>
              <!--单买按钮-->
              <pageBottomButton v-if="isSingle" color="yellow" buttonType="price" text="单独购买"
                :text-price="goodsGroup.single_price" @click="singeBuyButton" />

              <!--团购买按钮-->
              <pageBottomButton color="red" buttonType="price" :text="groupBuyButtonText" :text-price="goodsGroup.price"
                @click="groupBuyButton" />
            </template>
          </template>
        </template>
      </footerFixed>
    </template>
    <!--没有数据时显示缺省页-->
    <template v-else>
      <emptyGodds />
    </template>
    <goods-record-log v-if="recordLogList.length > 0" :list="recordLogList" />
  </div>
</template>

<script>
import goodsInfor from '@/components/goods/goods-infor'
import groupCountdown from '@/components/goods/goods-group-countdown'
import groupGoodsTips from '@/components/goods/goods-group-tips'
import groupGoodsPeoples from '@/components/goods/goods-group-peoples'
import footerFixed from '@/components/page-footer-fixed'
import commonHeader from '@/components/header/common'
import goodsSwipe from '@/components/goods/goods-swipe'
import pageBottomButton from '@/components/page-bottom-button'
import buttonShare from '@/components/page-button-share'
import emptyGodds from '@/components/empty/goods'
import goodsGroupPeoplesTitle from '@/components/goods/goods-group-peoples-title'
import goodsRecordLog from '@/components/goods/goods-record-log'

import {
  apiGroupDetail,
  apiOrderCreate,
  apigetRecordList,
  apiRecordLogList,
} from '@/api/goods'

import { getTeacherDetail } from '@/api/teacher'
import buttonApp from '@/components/page-button-app'
import commonMixins from '@/mixins/common'
import { updateAppMeta } from '@/utils/appDevice'

export default {
  mixins: [commonMixins],

  components: {
    goodsInfor,
    groupCountdown,
    groupGoodsTips,
    groupGoodsPeoples,
    footerFixed,
    commonHeader,
    goodsSwipe,
    pageBottomButton,
    buttonShare,
    emptyGodds,
    buttonApp,
    goodsGroupPeoplesTitle,
    goodsRecordLog,
  },
  data() {
    return {
      pageLoadingComplete: false, //页面加载完成

      id: 0, //商品id
      rid: 0, //团的id
      goodsData: {}, // 商品数据
      teacherList: [], // 老师列表
      isDisableButton: false, // 是否禁用按钮
      alreadyBuy: false, // 是否购买
      recommendGroupList: [], //推荐团列表
      recordLogList: [], //拼团动态推送

      isPeoplesButtonActive: false,//推荐团显示全部按钮
      recommendGroupListTimer: -1, //推荐列表定时器

      appMetaList: {
        //定义meta信息
        share_title: '',
        share_description: '',
        share_image:
          'https://appresource-1253756937.cos.ap-chengdu.myqcloud.com/skb/wv/pingtuan/share.png',
        share_url: '',
      },
    }
  },

  metaInfo() {
    return {
      meta: [
        { property: 'tiku:share', content: true },
        { property: 'tiku:share_title', content: this.appMetaList.share_title },
        {
          property: 'tiku:share_description',
          content: this.appMetaList.share_description,
        },
        { property: 'tiku:share_image', content: this.appMetaList.share_image },
        { property: 'tiku:share_url', content: this.appMetaList.share_url },
      ],
    }
  },
  computed: {
    // 邀请按钮文本
    inviteButtnText() {
      let { group_num, list } = this.goodsRecord || {}
      let num = group_num - list?.length
      return `差${num > 0 ? num : 0}人，邀请好友参团`
    },
    // 拼团购买按钮文案
    groupBuyButtonText() {
      let text = this.goodsGroup.num + '人成团'
      if (this.isManualGroup) {
        text = '发起拼团'
      }
      return text
    },
    // 是否可以单买 0不可以 1可以
    isSingle() {
      return this.goodsGroup.is_single === 1
    },
    // 商品团信息
    goodsGroup() {
      return this.goodsData.group
    },
    // 参团人数信息
    goodsRecord() {
      return this.goodsData.group_record
    },
    // 按钮团信息
    buttonRecord() {
      let result = this.goodsData.button_record
      if (this.alreadyBuy) {
        result.status = 2
        result.is_join = 1
      }
      return result
    },
    goodsGroupTimeOver() {
      return this.goodsGroup.end_time <= Date.parse(new Date()) / 1000
    },
    //是否是手动成团
    isManualGroup() {
      //group_rule, 1 自动成团， 2 手动成团
      return this.goodsGroup.group_rule === 2
    },
    // 显示拼团查看全部按钮
    isPeoplesButton() {
      return !this.isPeoplesButtonActive && this.recommendGroupList.length > 2
    },
    showRecommendGroupList() {
      return this.isPeoplesButtonActive ? this.recommendGroupList : this.recommendGroupList.slice(0, 2);
    }
  },
  created() {
    // document.title = this.getCurrentBrand.name + this.currentUnit.title
    this.getRouterParam()
    this.checkURLBuyState()
    this.getGoodsDetail()
    this.getRecordLogList()
  },
  mounted() {
    // 解决微信商品详情跳转订单界面，返回时链接不变
    let lastHistory = false
    this.$store.state.setup.pageHistory.forEach((item) => {
      lastHistory = item
    })
    //解决进入下一个页面后，返回当前页面，再点返回无效
    if (
      lastHistory.indexOf('goods/detail') < 0 &&
      lastHistory.indexOf('login') < 0
    ) {
      this.$util.locationHref(window.location.href)
    }

  },
  beforeDestroy() {
    clearInterval(this.recommendGroupListTimer)
  },
  methods: {
    // 更新meta信息
    updateAppMetaList() {
      this.appMetaList.share_title = this.goodsData.title
      this.appMetaList.share_description = this.goodsData.subtitle || this.goodsData.title
      this.appMetaList.share_url = window.location.href
      this.goodsData.share_img &&
        (this.appMetaList.share_image = this.goodsData.share_img)
      console.log(this.appMetaList.share_image)
      //app分享时，需要调用此方法 延长调用，否则app拿不到数据
      setTimeout(() => {
        updateAppMeta()
      }, 1000)
    },
    // 获取路由参数
    getRouterParam() {
      let { id, rid } = this.$route.query
      this.id = id
      this.rid = rid
    },
    // 获取商品详情
    getGoodsDetail() {
      let params = {}
      params.id = this.id
      if (this.rid) {
        params.r_id = this.rid
      }

      // 商品请求
      apiGroupDetail(params).then((res) => {
        if (res.status === 1) {
          let resdata = res.data
          // 初始化商品数据
          this.goodsData = this.formatGoodsDetail(resdata)
          let r_id = this.goodsRecord.id
          // 如果有团id且地址栏没有团id时，修改当前网址
          if (r_id && !this.$route.query.rid) {
            this.$util.replacePageState({}, '', window.location.href + '&rid=' + r_id)
            // 用户参团，且在进行中
          } else if (r_id && this.goodsRecord.status === 1) {
            let query = this.$route.query
            query.rid = r_id
            this.$util.replacePageState({}, '', this.$util.getGoodsDetailURL(query))
          }

          this.getTeacherDetailRequest(resdata.teacher.ids) // 获取老师列表
          this.getRecommendGroupList() //获取推荐组列表
          this.updateAppMetaList()

          let shareText = this.goodsData.subtitle
            ? this.goodsData.subtitle
            : `${this.goodsGroup.num}人成团，仅需¥${this.goodsGroup.price}，快来一起拼团吧。`

          // 设置当前页面title
          if (resdata.title) {
            document.title = resdata.title
          }
          // 设置分享标题
          this.$util.setShare({
            title: resdata.title,
            desc: `${shareText}`,
            link: window.location.href,
            imgUrl: resdata.share_img || '',
          })

          // 判断自动vuex列表中，是否有当前id，用于用户未登录时点击购买，登录后自动跳转订单界面
          if (this.$store.state.setup.autoBuyList.get(this.id)) {
            // 当用户登录后，且没有购买时，自动加入
            if (
              this.$store.state.user.token &&
              this.buttonRecord.status !== 2
            ) {
              let rid = this.$store.state.setup.autoBuyList.get(this.id)?.rid
              console.log(this.$store.state.setup.autoBuyList.get(this.id));

              if (rid) {
                this.goodsRecord.id = rid
                this.joinGroupBuyButton(this.goodsRecord)
              } else {
                this.groupBuyButton(this.goodsRecord)
              }
            }
          }

          // 跳转登录
        } else {
          this.goodsData = false
        }
        this.pageLoadingComplete = true // 页面加载完成
      })
    },
    // 获取推荐团列表
    getRecommendGroupList() {
      const param = {}
      param.id = this.$route.query.id
      if (this.rid) {
        param.r_id = this.rid
      }
      apigetRecordList(param).then((res) => {
        this.recommendGroupList = res.data || []
        // 没有推荐请求定时器，且推荐团有数据时，开启定时器
        if (
          this.recommendGroupListTimer === -1 &&
          this.recommendGroupList.length > 0
        ) {
          this.recommendGroupListTimer = setInterval(() => {
            this.getRecommendGroupList()
          }, 10000)
        }
        // 如果推荐团没有数据时，停止计时器
        if (this.recommendGroupList.length === 0) {
          clearInterval(this.recommendGroupListTimer)
        }
      })
    },
    // 获取团动态列表
    getRecordLogList() {
      let id = this.$route.query.id
      apiRecordLogList({ id }).then((res) => {
        const recordLogList = res?.data || []
        this.recordLogList = recordLogList
      })
    },
    formatGoodsDetail(goods) {
      let result = goods
      result.group.price = this.$util.clearPriceZero(result.group.price)
      result.group.single_price = this.$util.clearPriceZero(
        result.group.single_price
      )
      return result
    },

    // 获取老师列表
    getTeacherDetailRequest(list) {
      if (!list || list.length <= 0) return
      let id = list.join(',')
      getTeacherDetail({ id }).then((res) => {
        this.teacherList = res.data
      })
    },

    // 点击购买事件
    clickBuyButton(options) {
      let param = {}
      param.id = this.id
      param.is_single = options.singleBuy //1单买 0拼团
      if (options.rid) {
        param.rid = options.rid
      }
      this.createOrder(param)
    },
    // 单购购买
    singeBuyButton() {
      this.clickBuyButton({ singleBuy: 1 })
    },
    // 开团购买
    groupBuyButton() {
      this.$store.dispatch('setAutoBuyList', { id: this.id, val: true })
      this.clickBuyButton({ singleBuy: 0 })
    },
    // 加入指定团购买
    joinGroupBuyButton(record) {
      let rid = record.id || this.rid || 0
      console.log(rid);
      this.$store.dispatch('setAutoBuyList', { id: this.id, val: true, rid })
      this.clickBuyButton({ singleBuy: 0, rid })
    },

    selectBuyButton() {
      let param = this.goodsData.order_info

      this.$router.push({
        name: 'orderDetail',
        query: param,
      })
    },

    // 创建商品订单
    createOrder(param) {
      apiOrderCreate(param)
        .then((res) => {
          let resData = res.data || {},
            no = resData.no

          if (res?.message === '请先登录') {
            this.$router.push({ name: 'login' })
            return
          }
          if (!no) {
            this.$toast(res.message)
            return
          }
          this.$store.dispatch('setAutoBuyList', { id: this.id, val: false })
          this.$router.push({
            name: 'paymentPay',
            query: { no },
          }).catch(() => {
          })
        })
        .catch(() => {
          this.$toast('网络请求异常')
        })
    },

    // 检查url 购买状态
    checkURLBuyState() {
      let alreadyBuy = this.$route.query.alreadyBuy
      if (alreadyBuy !== undefined) {
        this.alreadyBuy = true
      }
    },
    // 拼团计时结束
    peoplesFinish() {
      console.log('拼团计时结束')

      let time = this.goodsRecord.end_time - Date.parse(new Date()) / 1000 || 0
      setTimeout(() => {
        this.getGoodsDetail()
      }, time + 3000)
    },
    // 商品倒计时结束
    goodsFinish() {
      console.log('商品计时结束')
      this.getGoodsDetail()
    },

    clickOpenApp() {
      if (this.$route.query.alreadyBuy === undefined) {
        let url = window.location.href + '&alreadyBuy'
        this.$util.replacePageState({}, '', url)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.goods {
  &-catalog {
    border-bottom: 0.2rem solid #f7f8fa;
    background: #fff;

    .header {
      padding-top: 0.5rem;
      margin-bottom: -0.1rem;

      .title {
        font-size: $font36;
        font-weight: bold;
      }

      .subtitle {
        font-size: $font28;
        color: #919499;
        margin-left: 0.2rem;
      }
    }

    .image {
      display: block;
      width: 100%;
    }
  }

  &-content {
    .image {
      width: 100%;
    }
  }
}

.container-buy {
  .goods-catalog {
    border: none;
  }
}

.container-wapper {
  min-height: 100vh;
  background-color: #fff;
  box-sizing: border-box;

  &.container-buy {
    .van-tab__pane {
      &:nth-last-child(1) {
        min-height: auto;
      }
    }
  }
}

.line {
  height: 0.21rem;
  background: #f7f8fa;
}

::v-deep {
  .group-goods-People+.group-goods-People .content {
    border-top: 0.01rem solid #EBECF0;
  }

  .peoples-button {
    @include flex_center;
    height: 1.09rem;
    font-size: 0.32rem;
    line-height: 0.35rem;
    color: #919499;
    border-top: 0.01rem solid #EBECF0;
    margin: 0 $containerPadding;
  }
}
</style>
