<template>

  <van-collapse v-model="activeNames" class="goodsTips" :border="false">
    <van-collapse-item name="1" :border="false">
      <template #title>
        <div><img src="../../assets/images/group/icon_question.png" class="icon">如何参与拼团</div>
      </template>
      <img src="../../assets/images/group/process.png" class="image">
    </van-collapse-item>
  </van-collapse>
</template>

<script>
export default {
  name: "goodsTips",
  data() {
    return {
      activeNames: ['0'],
    }
  }
}
</script>

<style scoped lang="scss">
.goodsTips {
  ::v-deep {
    .van-cell {
      padding: .2rem $containerPadding;
      border-bottom: .01rem solid #F7F8FA;

      &__title {
        font-size: 0.32rem;
        color: #3C3D42;

        .icon {
          width: 0.29rem;
          height: 0.29rem;
          margin-right: .1rem;
          transform: translateY(.02rem);
        }
      }

      &__right-icon {
        color: #919499;
        font-size: .3rem;
      }

    }

    .van-collapse-item__content {
      padding: 0;

      .image {
        width: 100%;
        min-height: 2.48rem;
      }
    }
  }
}
</style>
