<template>
  <div class="goods-swipe">
    <van-swipe @change="onChange">
      <van-swipe-item v-for="(item,index) in bookList" :key="index">
        <img :src="item" alt="" class="image">
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator" v-if="bookList.length>1"><span class="number">{{ current + 1 }}</span>/ {{ bookList.length }}</div>
      </template>
    </van-swipe>
  </div>
</template>

<script>
export default {
  props: ['bookList'],
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
  },
}
</script>

<style scoped lang="scss">
.custom-indicator {
  position: absolute;
  right: 0;
  width: 1.23rem;
  height: 0.48rem;
  bottom: .67rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3rem 0 0 3rem;
  font-size: .27rem;
  opacity: 0.4;
  color: #fff;
  text-align: center;
  font-family: DIN-Medium;

  .number {
    font-size: .4rem;
  }
}

.goods-swipe {
  .van-swipe-item {
    height: 10rem;
  }

  .image {
    max-width: 100%;
    width: 100%;
  }
}
</style>
