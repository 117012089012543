<template>
  <van-empty description="没有找到商品" />
</template>

<script>
export default {
name: "goods"
}
</script>

<style scoped>
.van-empty{
  padding-top: 4rem;
}
</style>
