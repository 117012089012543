<template>
  <div class="tips" :class="{'fadein':show}">
    <span class="text">{{ user.nickname }} </span>
    <span class="text">{{ user.text }}</span>

  </div>
</template>

<script>
import { getGoodsRecordLogIndex, setGoodsRecordLogIndex } from "@/cookies/setup";

export default {
  name: "goods-record-log",
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      show: false,
      user: {},
      timerTime: 3000,
      timer: -1,//计时器
      animationShowTime: 2000,//显示时长
      userIndex: getGoodsRecordLogIndex() > 0 || 0,
    }
  },
  mounted () {
    this.startTimer()

  },
  beforeDestroy () {
    clearTimeout(this.timer)
  },
  methods: {
    random (min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    // 显示用户信息
    showUser () {
      this.show = true
      this.updateUser()
      setTimeout(() => {
        this.show = false
      }, this.animationShowTime)
    },
    // 更新用户信息
    updateUser () {
      this.updateUserIndex()
      this.user = this.list[this.userIndex]
    },
    updateUserIndex () {
      if (this.userIndex > this.list.length - 2) {
        this.userIndex = -1
      }
      this.userIndex += 1
      setGoodsRecordLogIndex(this.userIndex)
    },
    // 更新计时器执行时间
    updateTimerTime () {
      this.time = this.random(2, 5) * 1000 + this.animationShowTime + 600
    },
    startTimer () {
      let time = this.time
      this.timer = setTimeout(() => {
        this.showUser()
        this.updateTimerTime()
        this.startTimer()
      }, time)
    }
  }
}
</script>

<style scoped lang="scss">
.tips {
  position: fixed;
  left: .32rem;
  top: 1.7rem;
  // bottom: 1.9rem;
  height: 0.75rem;
  display: flex;
  align-items: center;
  background: rgba(#000, .6);
  border-radius: .1rem;
  font-size: 0.32rem;
  color: #FFFFFF;
  padding: 0 .2rem;
  transition: .3s ease-in;
  opacity: 0;

  .text+.text {
    display: inline-block;
    margin-left: .2rem;
  }

  &.fadein {
    opacity: 1;
  }
}
</style>
