<template>
	<div>
		<ul class="teacer-list">
			<li v-for="(item, index) in list" :key="index">
				<div class="teacer-card ">
					<img :src="item.portrait" class="portrait" />
					<div class="teacer-card-content ">
						<p class="name">{{ item.name }}</p>
						<p class="title">{{ item.title }}</p>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {}
	},
}
</script>

<style lang="scss" scoped>
.teacer {
	&-list {
		margin-top: -0.05rem;
		overflow-x: auto;
		white-space: nowrap;
		margin-right: -0.4rem;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE 10+ */
		&::-webkit-scrollbar {
			display: none; /* Chrome Safari */
		}
		> li {
			display: inline-block;
			margin-right: 0.4rem;
		}
		.teacer-card {
			width: 4.78rem;
			display: flex;
      height: 1.22rem;
			&-content {
				flex: 1;
			}
			.portrait {
				width: 1.22rem;
				height: 1.22rem;
				margin-right: 0.43rem;
				border-radius: 100px;
			}
			.name {
				margin-top: 0.05rem;
				font-size: 0.4rem;
				margin-bottom: 0.1rem;
			}
			.title {
				color: #c2c5cc;
				font-size: 0.35rem;
			}
		}
	}
}
</style>
