<template>
  <div>
    <van-row class="group-goods-order" :class="isOver?'over':''">
      <van-col span="16" class="item left van-row--flex">

        <div class="price">
          <span class="symbol">￥</span>
          <span class="number">{{ data.price }}</span>
        </div>
        <div class="detail">
          <p class="count" :style="data.single_price<=0?'margin-top:.28rem':''">{{ data.num }}人成团</p>
          <p class="text" v-if="data.single_price>0">单买￥{{ data.single_price }}</p>
        </div>
      </van-col>
      <van-col span="8" class="item right ">
        <div style="display: block;width: 100%;text-align: center">
          <div class="title">
            距拼团结束还剩
          </div>
        </div>
        <dayTimer :time="endTime" @finish="dayTimerFinish"></dayTimer>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import dayTimer from "@/components/group-day-timer";

export default {
  name: "goodsTimer",
  props: ['data'],
  data() {
    return {
      isOver: false
    }
  },
  components: {
    dayTimer
  },
  computed: {
    endTime() {
      let time = this.data.end_time - Date.parse(new Date()) / 1000
      return time >= 0 ? time : 0
    }
  },
  methods: {
    dayTimerFinish() {
      this.isOver = true
      this.$emit('finish')
    }
  },
}
</script>

<style scoped lang="scss">

.group-goods-order {
  position: relative;
  @include background-image(100%);
  background-image: url('../../assets/images/group/goods_timer_background.png');
  margin-top: -.14rem;

  &.over:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: .5;
  }

  .item {
    height: 1.6rem;

  }

  .left {
    padding-left: .4rem;
    padding-top: .48rem;
    box-sizing: border-box;
    font-family: DIN-Medium;

    .price {
      color: #fff;

      .symbol {
        font-size: 0.53rem;

      }

      .number {
        font-size: 0.85rem;
        font-weight: bold;
        line-height: 1;
      }
    }

    .detail {
      margin-left: .2rem;
      font-size: 0;

      .count {
        display: inline-block;
        background: rgba(255, 255, 255, 0.9);
        border-radius: .08rem;
        font-size: 0.29rem;
        color: #FF6A4D;
        padding: .07rem .13rem;
        margin-bottom: .1rem;
        line-height: 1;
      }

      .text {
        font-size: 0.27rem;
        line-height: 0.29rem;
        color: #FFEACC;
      }
    }
  }

  .right {
    @include flex-column_center;
    padding-right: .4rem;
    box-sizing: border-box;

    .title {
      @include text-decorate(url('../../assets/images/group/icon_title_decorate.png'), .24rem, .13rem);
      font-size: 0.29rem;
      color: #FF6A4D;
      margin-bottom: .1rem;
      white-space: nowrap;
    }
  }
}

</style>
