<template>
  <div class="group-goods-People" v-if="record.status > 0&&record.is_single!==1">
    <goods-group-peoples-title :isSuccsess="record.status === 2" v-if="showTitle" />
    <div class="content">
      <div class="van-row--flex van-row--justify-center van-cell--center">
        <div style="flex: 1">
          <div class="people van-row--flex van-cell--center">
            <img :src="record.list_info.portrait" class="people-imge" />
            <p class="people-name">{{record.list_info.nickname}}</p>
            <!-- <template v-if="record.status===2&& record.list.length>5">
              <img src="../../assets/images/group/people_list_ellipsis3.png" class="people-imge" />
            </template> -->
            <!-- 
            <template v-if="record.status!==2">
              <img src="../../assets/images/group/people_icon_ellipsis.png" class="ellipsis" />
              <img src="../../assets/images/group/people_list_default.png" class="people-imge" />
            </template> -->

          </div>
        </div>
        <div class="van-row--flex van-row--justify-center van-cell--center">
          <div class="infor">
            <!-- record.status 0:未成团 1:进行中 2:已成团 3:退款中 4:退款成功-->
            <!--团进行中-->
            <template v-if="record.status === 1">
              <p class="name">
                还差<span class="number">{{ record.group_num - record.list.length }}</span>人成团
              </p>
              <p class="time">
                <van-count-down :time="endTime" @finish="onFinish">
                  <template #default="timeData">
                    <span class="block">剩余</span>
                    <span class="block" v-if="timeData.days > 0">{{ timeData.days }}天</span>
                    <span class="block">{{ zeroFill(timeData.hours) }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ zeroFill(timeData.minutes) }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ zeroFill(timeData.seconds) }}</span>
                  </template>
                </van-count-down>
              </p>
            </template>

            <!--拼团成功-->
            <template v-else-if="record.status === 2">
              <p class="name">
                <span class="number">{{ record.group_num }}</span>人已成团
              </p>
              <p class="time">
                <van-count-down :time="surplusTime" :auto-start="false">
                  <template #default="timeData">
                    <span class="block">剩余 </span>
                    <span class="block" v-if="timeData.days > 0">{{ timeData.days }}天</span>
                    <span class="block">{{ zeroFill(timeData.hours) }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ zeroFill(timeData.minutes) }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ zeroFill(timeData.seconds) }}</span>
                  </template>
                </van-count-down>
              </p>
            </template>

            <!--拼团失败-->
            <template v-if="record.status === 3 || record.status === 4">
              <p class="name">
                还差<span class="number">{{
                record.group_num - record.list.length
                }}</span>人成团
              </p>
              <p class="time">成团有效时间已过</p>
            </template>
          </div>

          <!--团状态-->
          <div class="state">
            <!--进行中，并未参加-->
            <van-button round type="info" color="#FF4D4D" class="button"
              v-if="record.status === 1 && record.is_join === 0" @click="groupButton">去参团
            </van-button>
            <!--进行中，已参加-->
            <van-button round type="info" color="#FF4D4D" class="button"
              v-else-if="record.status === 1 && record.is_join === 1" @click="showOverlayButton">去邀请
            </van-button>
            <!--成功-->
            <img src="../../assets/images/group/people_icon_success.png" class="imge" v-else-if="record.status === 2" />
            <!--失败-->
            <img src="../../assets/images/group/people_icon_fail.png" class="imge"
              v-else-if="record.status === 3 || record.status === 4" />
          </div>
        </div>
      </div>
    </div>

    <!--好友邀请提示-->
    <van-overlay :show="showOverlay" @click="showOverlay = false" z-index="999">
      <img class="image-tips" :src="require('@/assets/images/common/share_tips.png')" />
    </van-overlay>
  </div>
</template>

<script>
import goodsGroupPeoplesTitle from '@/components/goods/goods-group-peoples-title'

export default {
  name: 'goodsPeoples',
  props: {
    record: {
      type: Object,
      default: () => {
      }
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  components: {
    goodsGroupPeoplesTitle
  },
  data () {
    return {
      showOverlay: false, //好友邀请提示
      isCall: false, //是否已经回调
    }
  },
  computed: {
    zeroFill () {
      return function (number) {
        return parseInt(number) > 9 ? number : '0' + number
      }
    },
    endTime () {
      return this.record.end_time * 1000 - Date.parse(new Date())
    },
    // 剩余时间
    surplusTime () {
      let time = this.record.end_time - this.record.join_time || 0
      return time * 1000
    },
    showList () {
      // record.status 0:未成团 1:进行中 2:已成团 3:退款中 4:退款成功
      let list = [...this.record.list]
      let status = this.record.status
      if (list.length > 5) {
        list = list.splice(0, 5)

      }
      if (status === 1) {
        list = list.splice(0, 3)
      }
      return list
    }
  },
  methods: {
    onFinish (args) {
      if (this.isCall) return
      this.isCall = true
      this.$emit('finish', function () {
        return args
      })
    },
    groupButton () {
      this.$emit('groupButton', this.record)
    },
    showOverlayButton () {
      this.showOverlay = true
    },
  },
}
</script>

<style scoped lang="scss">
.group-goods-People {
  padding: 0 $containerPadding;


  .content {
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
  }

  .people {
    &-imge {
      width: 0.91rem;
      height: 0.91rem;
      border-radius: 50%;
      background-color: #fff;

      &+.people-imge {
        margin-left: -0.22rem;
      }
    }

    .ellipsis {
      width: 0.35rem;
      height: 0.08rem;
      margin: 0 0.16rem;
    }

    &-name {
      flex: 1;
      @include ellipsis;
      font-size: 0.35rem;
      color: #3C3D42;
      margin-left: .2rem;
      margin-right: .4rem;
    }
  }

  .infor {
    margin-right: 0.2rem;

    .name {
      font-size: 0.32rem;
      color: #3c3d42;

      .number {
        color: #ff6a4d;
      }
    }

    .van-count-down {
      color: #919499 !important;
    }

    .time {

      &,
      .block {
        font-size: 0.29rem;
        color: #919499 !important;
      }
    }
  }

  .state {
    @include flex_center;
    height: 1.6rem;

    .button {
      padding: 0;
      width: 2.13rem;
      height: 0.8rem;
      font-size: 0.35rem;
      color: #ffffff;
    }

    .imge {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.image-tips {
  position: absolute;
  top: 0.4rem;
  right: 0.6rem;
  width: 8.24rem;
}
</style>
