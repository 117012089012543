<template>
  <div class="header">
    <div class="title">
      <img src="../../assets/images/group/people_title_icon.png" class="icon"/>
      {{ isSuccsess ? '好友团已满，请发起新团或参与其他团' : '进行中的团，可直接参与' }}

    </div>
  </div>
</template>

<script>
export default {
  name: "goods-group-peoples-title",
  props: ['isSuccsess']
}
</script>

<style scoped lang="scss">
.header {
  padding-top: 0.59rem;

  .title {
    font-size: 0.37rem;
    font-weight: bold;
    line-height: 1;
    color: #3c3d42;

    .icon {
      width: 0.08rem;
      height: 0.32rem;
      margin-right: 0.2rem;
    }
  }
}
</style>
