import request from '@/utils/request'

// 商品详情
export function apiGroupDetail(data) {
    return request({
        url: 'group/detail',
        method: 'post',
        data: data,
    })
}


// 订单创建
export function apiOrderCreate(data) {
    return request({
        url: 'order/create',
        method: 'post',
        data: data,
    })
}

/**
 * 获取推荐团列表
 * @param data
 * @param data.id 商品id
 */
export function apigetRecordList(data) {
    return request({
        url: 'group/get_record_list',
        method: 'post',
        data: data,
    })
}

/**
 * 获取用户平团成功列表
 * @param data
 * @param data.id 商品id
 * @return {AxiosPromise}
 */
export function apiRecordLogList(data) {
    return request({
        url: 'group/get_log_list',
        method: 'post',
        data: data,
    })
}



